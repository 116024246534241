import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×4\\@85% 1RM`}</p>
    <p>{`Strict Chin Ups 4×4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`Three 2:00 Rounds of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`Max S2OH reps (115/75)`}<em parentName="p">{`(RX+ 155/105)`}</em></p>
    <p>{`Rest 1:00`}</p>
    <p>{`Score = Total S2OH reps`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`5:00 AMRAP of:`}</p>
    <p>{`10 T2B`}</p>
    <p>{`10 Burpees`}</p>
    <p><em parentName="p">{`*`}{`Jump to touch bar at end of each burpee`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday (make up day) we will meet at Perrin Park (414 Perrin Lane,
Jeffersonville, IN 47130), weather permitting, for a fun outdoor wod on
the fitness loop at our normal class time of 12:30.  The park is 5 miles
from The Ville, just over 2nd street bridge about 4 miles down Utica
Pike.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      